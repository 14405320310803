<template>
  <span>
    <v-row class="mt-5 mx-auto" justify="center">
      <v-col cols="12" sm="10">
        <v-card>
          <v-card-text>
            <highcharts :options="chartsOptionsQtdErrosPorTema" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  export default {
    name: "ChartsGeralComercial",
    props: {
      temas: {
        type: Array,
        required: true,
      },
      valores: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      //
    }),
    computed: {
      chartsOptionsQtdErrosPorTema() {
        return {
          chart: {
            zoomType: "y",
          },
          title: {
            text: "Quantidade de erros de validação por tema",
          },
          legend: { enabled: false },
          yAxis: {
            title: { text: "" },
          },
          xAxis: {
            categories: this.temas.map((tema) => tema.text),
            title: { text: null },
          },
          series: [
            {
              type: "column",
              color: "#003A63",
              name: "Erros",
              label: { enabled: false },
              data: this.valores,
            },
          ],
        };
      },
    },
  };
</script>

<style></style>
